body.interna {
    background-size: cover;
   
}

body.palmares {
    background: url(../images/libertad/fondopalmares.jpg);
    background-repeat: no-repeat;
}

section.palmares-sec {
    
    .palmar-item {
        display:flex;
        flex-direction: column;
        margin-bottom: 4em;

        h3 {
            margin-bottom:0px;
            margin-top: 12px;
        }

        img.subcampeonicon {
            height:40px;
            margin: .2em;
        }

        img.textcampeon {
            height:30px;
        }

        .estrellascampeonato {
            display:flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-top:20px;
            padding: 0 4em;

            img {
                height:28px;
            }
        }
    }
}

@media (max-width:992px) {
    body.interna {
        background-size: contain;
        background-position: 72px center;
        background-position: 0 70px;
    }
}