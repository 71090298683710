body section:first-of-type:not(.homeslider) {
    padding-top:162px;
}

section .container {
    z-index:991;
    position:relative;
}

section {
    padding-top:62px;
    padding-bottom: 62px;
}

section.section-mdm {
    padding-top: 40px;
    padding-bottom: 40px;
}

section.section-sml {
    padding-top: 20px;
    padding-bottom: 20px;
}

a.icon-hover-toggler {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    
    img.icon-off {
        // display:inherit;
        opacity:1;
        position: absolute;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        left: calc(50% - 32px);
    }
    img.icon-on {
        // display:none;
        opacity:0;
        position: absolute;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        left: calc(50% - 32px);
    }

    &:hover {
        img.icon-off {
            // display:none;
            opacity:0;

        }
        img.icon-on {
            // display:inherit;
            opacity:1;

        }
    }
}

.titular-seccion {
    flex-direction: column;
}


@media(max-width:992px) {
    
    
    section {
        padding-top:40px;
        padding-bottom: 40px;
    }
    
    .container {
        width: 100%;
        padding: 0 9%;
        max-width: unset;
    }
}

.whitestripes {
    width:100vw;
    height:100vh;
    position:fixed;
    display:flex;
    flex-direction: row;
    z-index: 990;

    .franja {
        width:20%;
        height:100vh;
        border-right:solid 1px rgba(255,255,255,0.5);
    }
}

.blackstripes {
    width:100vw;
    height:100vh;
    position:fixed;
    display:flex;
    flex-direction: row;
    z-index: 990;

    .franja {
        width:calc(33.3333% - 1px);
        height:100vh;
        border-right:solid 1px rgba(0,0,0,0.2);

    }
    .franja:first-child {
        border-left:solid 1px rgba(0,0,0,0.2);
    }
}

section.social-icons-inc {
    .social {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: black;
            font-size: 24px;
            margin: .25em;

            &:hover {
                color: var(--transpblack);
            }
        }
    }
}