@font-face {
    font-family: 'recoleta_altblack';
    src: url('../fonts/latinotype_-_recoleta_alt_black-webfont.woff2') format('woff2'),
         url('../fonts/latinotype_-_recoleta_alt_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }

/*----------------END NEW FONTS----------------------------------------------*/

//text-colors

.txt-white {
    color: var(--blanco);
}

.txt-black {
    color: var(--negro);
}

.txt-recoleta {
    font-family: 'recoleta_altblack';
}



//titulares

h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
}

h1 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:64px;
    line-height: 1em;
    margin-bottom: .5em;
}

h2 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:60px;
    line-height: 1em;
    margin-bottom: .5em;
}

h3 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:40px !important;
    line-height: 1em;
    margin-bottom: .5em;
}

h4 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:30px;
    line-height: 1em;
    margin-bottom: .5em;
}

h5 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:25px;
    line-height: 1em;
    margin-bottom: .5em;
}

h6 {
    font-weight:bold;
    font-family: $font-family-bold;
    font-size:20px;
    line-height: 1em;
    margin-bottom: .5em;
}

//font weight

.txt-regular {
    font-weight: normal;
}

.txt-medium {
    font-family: $font-family-medium;
    font-weight: 500;
}

.txt-bold {
    font-family: $font-family-bold;
    font-weight: 700;
}

.txt-medium-italic {
    font-family: $font-family-medium-italic;
    font-weight: 500;
}

.txt-bold-italic {
    font-family: $font-family-bold-italic;
    font-weight: 700;
}

//texto corrido

p {
    font-size:16px;
}

.txt-xsmll {
    font-size:12px;
}

.txt-smll {
    font-size:14px;
}

.txt-mdm {
    font-size:18px;
}

.txt-big {
    font-size:21px;
}

//fontstyle

.ttu {
    text-transform: uppercase;
}


@media(max-width:992px) {
    h1 {
        font-size:48px;
    }
    h2 {
        font-size:40px;
    }
    h3 {
        font-size:30px;
    }
    h4 {
        font-size:24px;
    }
    h5 {
        font-size: 20px;
    }
    h6 {
        font-size: 18px;
    }

    .txt-mdm {
        font-size: 16px;
    }

}