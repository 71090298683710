body.interna {
    background-size: cover;

}

body.plantel {
    background: url(../images/libertad/fondoplantel.jpg);
    background-repeat: no-repeat;
}



section.plantel-jugadores {

    .jugador-item {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .puesto {
            letter-spacing: .35em;
            font-size:18px;
        }

        .nacionalidad {
            font-size:14px;
            font-weight: bold;
        }

        img {
            padding:3em;
            border-radius: 3.8em;
        }
    }
}

h3.cdtitle {
    font-size:24px !important;
}

@media (max-width:992px) {
    body.interna {
        background-size: contain;
        background-position: 72px center;
        background-position: 0 70px;
    }
}
