header.rojo {
    background:red !important;
}
header {
    position: fixed;
    width: 100%;
    background: transparent;
    z-index: 999;
    top:24px;
    height: 72px;

    .container-fluid {
        height: 72px;
        padding: 0;
        background: var(--negro);
        margin-left: calc(10% + 84px);
        width: calc(90% - 84px);
        padding-right: 80px;
    }

    nav.navbar {
        padding:0;
        align-items: center;
        a.navbar-brand {
            padding-top: 0;
            padding-bottom: 0;

            img {
                width: 168px;
                margin-left: -84px;
                margin-top: -7px;
            }
        }

        .navbar-collapse {
            //height: 78px;
            margin-top: -7px;
            align-items: center;
            justify-content: space-between;
        }

        .navbar-toggler {        
            padding: 12px 13px;
            border:0;
            .navbar-toggler-icon {
                background-image:url("../images/close-white.svg");
                display: inline-block;
                width: 18px;
                height: 12px;
                vertical-align: middle;
                //content: "";
            }
        }

        .navbar-toggler.collapsed {       
            padding: 12px 13px;
            border:0;

            .navbar-toggler-icon {
                background-image:url("../images/hambur-white.svg");
                display: inline-block;
                width: 18px;
                height: 12px;
                vertical-align: middle;
              //  content: "";
            }
        }
    }

    ul.navbar-nav {
        li.nav-item {
            a.nav-link {
                font-size: 17px;
                color: var(--blanco);
                text-decoration: none;
                font-family: $font-family-base;
                padding: 0 .4em;
                text-transform: uppercase;
                &:hover {
                    color: var(--transpwhite);
                }
            }
            .dropdown-toggle::after {
                content:none;
            }
            //submenu
            .dropdown-menu {
                font-size:14px;
                border: 1px solid #eceeef;
                border-radius: 0;
                padding:0;

                .dropdown-item {
                    padding: 12px 16px;

                    &:hover {
                        background:var(--negro);
                        color: var(--blanco);
                    }
                }
            }
        }

        li.nav-item.active {
            a.nav-link {
                color: red;
                background-position: bottom center;
                background-repeat: no-repeat;
            }
           
        }

        li.contactanos_cta {
            a {
                margin-left: 40px;
            }
        }
    }

    ul.navbar-nav.socialmedianav {
        margin-left: 1em;
        border-bottom: solid 3px white;
        display: flex;
        align-items: center;
        height: 72px;
    }

    ul.navbar-nav.menuitems {
        align-items: center;
    }
    
}

.pumatop {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10000;
    // background: white;

    img {
        width:69px; 
        height:102px
    }
}

.pumatop3 {
    position: fixed;
    top: 30px;
    right: 0;
    z-index: 10000;
    // background: white;

    img {
        width:72px; 
        height:72px
    }
}

.grafismotop {
    position: fixed;
    top: 0;
    right: 0;
    width: 39.5%;
    z-index: 998;

    img {
        height:103px;
    }
}

@media (max-width:992px) {
    //Hamburguesa activada

    .grafismotop {
        display:none;
    }
    header {
        position: fixed;
        width: 100%;
        z-index: 999;
        background:black;
        top:0;

        nav.navbar {
            padding:0;
            height: 72px;

            a.navbar-brand {
    
                img {
                    width: unset;
                    height: 60px;
                    margin-left: -60px;
                    margin-top: 0px;
                }
            }
        }
        

        .navbar-collapse, .navbar-collapse.collapsing, .navbar-collapse.show, .navbar-collapse.collapsed {
            position: absolute;
            top: 0px;
            width: calc(90vw - 84px);
            left: 0%;
            background: var(--negro);
            z-index: -1;
            padding-left: 0;
            padding-right: 0;

            ul.navbar-nav {
                height: auto;
                padding-top: 80px;
                li.nav-item {
                    a {
                        font-size:22px;
                        padding: .2em .4em;
                    }
                }
            }

            ul.navbar-nav.socialmedianav {
                margin-left: 0;
                flex-direction: row;
                justify-content: center;
            }

            ul.navbar-nav.menuitems {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }

    .pumatop {
        top:-28px;
    }

    .pumatop3 {
        top:0px;
    }

}



@media (min-width:976px) and (max-width:1120px) {
    header ul.navbar-nav li.nav-item a.nav-link {
        font-size:14px;
    }

}