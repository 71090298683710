body.historia {
    background: url(../images/libertad/fondohistoria.jpg);
    background-repeat: no-repeat;
}

section.historia-section {
    .pelota {
        width: 14px;
        margin-bottom: -20px;
        z-index: 2;
        position: relative;
    }

    .containercard {
        background: url(../images/libertad/dotted.png);
        background-repeat: repeat-y;
        background-position: center center;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .historia-item-card {
        background: white;
        border-radius: 12px;
        box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
        margin-bottom: 2em;

        .yearhistory {
            position: absolute;

            left: -5em;
            top: 42px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            display:none;

            span {
                font-size: 50px;
                line-height: 0.8;
            }
        }

        h5 {
            a {
                color:black;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        img {
            height: 200px;
            width: 100%;
            object-fit: cover;
            object-position: top center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            filter: sepia(1);
        }

        .newsdata {
            padding: 1.5em;
            text-align-last: left;

            .newsdate {
              display: none;
            }


            .tagcloud {
                margin-top:1em;

                a {
                    background: black;
                    color: white;
                    padding: .35em 1em;
                    border-radius: .35em;
                    margin-right: 1em;
                    text-decoration: none;
                    font-size:12px;

                    &:hover {
                        background: var(--transpblack);
                    }
                }
            }
        }
    }

    .historia-detalle {
        img.imghistory {
            border-radius:1em;
            width:100%;
        }

        p {
            font-size:17px;
        }
    }

    .timelinerow {
        background: url(../images/libertad/timeline.png) bottom center no-repeat;
        background-size:94%;
        padding-bottom: 40px;
        align-items: flex-end;
        display: none;

        .prevhistory {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .yearsplit {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-right:20px;

                span {
                    line-height:0.8;
                    font-size:34px;
                }
            }
            .infohistory {
                text-align-last: left;

                h6 a {
                    color:black;
                }
            }
        }
        .nexthistory {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .yearsplit {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-left:20px;

                span {
                    line-height:0.8;
                    font-size:34px;
                }
            }
            .infohistory {
                text-align: right;

                h6 a {
                    color:black;
                }
            }
        }

        .yearsplitbig {
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
                font-size:94px;
                line-height: 0.8;
            }
        }
    }
}

section.history-titulo {
    .titular-history {
        flex-direction: column;

        .newsdate {
          display: none;
        }

        a.historytag {
            text-align: center;
            padding: 0.3em 1em;
            background: white;
            border-radius: 12px;
            display: block;
            width: 24%;
            margin: 0 auto;
            color: black;
        }

        h1.year {
            font-size:90px;
            display: none;
        }
    }

}

@media (max-width:992px) {
    section.historia-section .historia-item-card .yearhistory {
        left: 18px;
        top: 48px;
        z-index: 2;
    }

    section.historia-section {
        .timelinerow {

            .prevhistory {
                .yearsplit {
                    display: none;
                }
            }
            .nexthistory {
                .yearsplit {
                    display: none;
                }
            }

            .yearsplitbig {
                span {
                    font-size:64px;
                }
            }
        }
    }

    section.history-titulo {
        .titular-history {

            a.historytag {

                width: 80%;

            }
        }
    }
}
