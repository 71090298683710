.carousel {
    height: auto;
}

.carousel-item {
    background:grey;
    height:auto;

    .infoslide {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0px;
        height: 100%;
        justify-content: flex-start;
        z-index: 991;
        padding-top: 15%;
        background: rgba(0,0,0,0.5);

        h2 {
            padding:.8em;
            margin-bottom: 0;
            text-align: center;
        }
    }
}

.homeslider {
    padding-top: 0px;
}

.carousel-indicators, .carousel-control-prev, .carousel-control-next {
    z-index: 991;
}

@media (max-width:992px) {
    .homeslider {
        padding-top:72px;
    }
}

@media (max-width:768px) {
    .carousel {
        height:70vh;
        .carousel-item {
            height:70vh;

            img {
                height:70vh;
                object-fit: cover;
            }
        }
    }
}

.proximoyultimo {
    position: absolute;
    z-index: 992;
    width: 100%;
    bottom: 30vh;

    .proximo {
        width: 20%;
        margin-left: calc(20% + 4px);
        background: rgba(255,255,255,0.6);
        padding: 1em;
        border-radius: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 1px 1px 5px white;
        position: absolute;

        .infomatch {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            span.tournament {
                font-size:10px;
            }
            span.matchdate {
                font-size:14px;
            }
        }

        .matchdetails {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                height: 50px;
                width: 80px;
                object-fit: contain;
                object-position: center;
            }

            span.matchhour {
                font-size: 36px;
                align-self: center;
            }
        }

        .matchplace {
            display: flex;
            flex-direction: column;
            align-items: center;

            span.estadio {
                font-size:10px;
            }
            span.matchstadium {
                font-size: 12px;
            }
        }
    }

    .ultimo {
        width: 20%;
        margin-left: calc(60% + 12px);
        background: rgba(0,0,0,0.6);
        padding: 1em;
        border-radius: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 1px 1px 5px black;
        position: absolute;

        .infomatch {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            span.tournament {
                font-size:10px;
            }
            span.matchdate {
                font-size:14px;
            }
        }

        .matchdetails {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                height: 50px;
                width: 80px;
                object-fit: contain;
                object-position: center;
            }

            span.matchhour {
                font-size: 36px;
                align-self: center;
            }
        }

        .matchplace {
            display: flex;
            flex-direction: column;
            align-items: center;

            span.estadio {
                font-size:10px;
            }
            span.matchstadium {
                font-size: 12px;
            }
        }
    }
}

@media (max-width:1160px) {
    .proximoyultimo {
        position: relative;
        bottom:0;

        .proximo {
            position: relative;
            left:0;
            width:100%;
            margin-left:0;
        }
        .ultimo {
            position:relative;
            left:0;
            width:100%;
            margin-left:0;
            background: rgba(0,0,0,0.9);
            border-radius: 0;

        }
    }
}