.hero-img {
    padding-bottom: 40px;
}

//soluciones





.blacklayer {
    min-height: 380px;
    background: rgba(0,0,0,0.3);
    position: relative;
    top: 0;
    border-radius: 1em;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}



@media (max-width:992px) {


    .blacklayer {
        width:100%;
        min-width: unset;
    }


}

