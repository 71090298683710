.btn_slide_tag {
    background-color: var(--blanco);
    color: var(--negro);
    font-weight: bold;
    font-size:18px;
    text-align: center; 
    padding: .2em 1.5em;
    border-radius: .4em;
}

.btn_white_brdr {
    background-color: transparent;
    color: var(--blanco);
    font-weight: bold;
    font-size:14px;
    text-align: center; 
    padding: .5em 1em;
    border-radius: .4em;
    border:solid 1px var(--blanco);

    &:hover {
        background-color: var(--blanco);
        color:var(--negro);
        border-color: transparent;
    }
}