footer {
   
    width:100%;
    background: var(--negro);
    color: var(--blanco);
    z-index: 991;
    position: relative;
    // margin-top:60px;

    .social-footer {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: white;
            font-size: 24px;
            margin: .25em;

            &:hover {
                color: var(--transpwhite);
            }
        }
    }

    .footer-info {
        padding-top: 80px;

        .gumastorelink {
            text-align: right;
        }
    }

    ul.footernav {
        list-style: none;
        padding-top: 24px;
        margin-left: 0;
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        li {
            float:left;

            a {
                color: white;
                font-size:16px;
                text-transform: uppercase;
                padding:10px;

                &:hover {
                    color: var(--transpwhite);
                }
            }
        }
    }

    .footerspan {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 14px;

        span {
            margin-bottom: .5em;
        }
    }

}

.libertadfooterimg {
    margin-top:-1px;
}

.sponsors {
    box-shadow: 0 0 15px #0000001f;
    position: relative;
    z-index: 991;
    background: white;
    margin-top: 40px;
  }
  
  .sponsors img {
    height: 60px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  }

@media (max-width:992px) {

    footer {
        .footer-info {
            padding-top: 40px;

            .gumastorelink {
                text-align: center;
            }
        }

        ul.footernav {
            flex-direction:column;
        }
    }

    .libertadfooterimg {
        width:50%;
    }

   
}