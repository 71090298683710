//Main colors
:root {
    --blanco:          #ffffff;
    --negro:       	   #000000;
	--transpwhite: 	   rgba(255,255,255,0.8);
  --transpblack:      rgba(0,0,0,0.4);
  }

$body-color: var(--negro);
$body-bg: var(--blanco);

//Main typography

$font-family-base:          'Roboto Condensed', sans-serif;
$font-family-base-italic:   'Roboto Condensed', sans-serif;

$font-family-light:         'Roboto Condensed', sans-serif;
$font-family-light-italic:  'Roboto Condensed', sans-serif;

$font-family-medium:        'Roboto Condensed', sans-serif;
$font-family-medium-italic: 'Roboto Condensed', sans-serif;

$font-family-bold:          'Roboto Condensed', sans-serif;
$font-family-bold-italic:   'Roboto Condensed', sans-serif;

$font-family-semibold:      'Roboto Condensed', sans-serif;
