body.noticias {
    background: url(../images/libertad/fondonoticias.jpg);
    background-repeat: no-repeat;
    background-size: contain;
}

section.noticias-section {
    .noticia-item-card {
        background: white;
        border-radius: 12px;
        box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
        margin-bottom: 2em;

        h5 {
            a {
                color:black;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        img {
            height: 200px;
            width: 100%;
            object-fit: cover;
            object-position: top center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }

        .newsdata {
            padding: 1.5em;

            .tagcloud {
                margin-top:1em;

                a {
                    background: black;
                    color: white;
                    padding: .35em 1em;
                    border-radius: .35em;
                    margin-right: 1em;
                    text-decoration: none;
                    font-size:12px;

                    &:hover {
                        background: var(--transpblack);
                    }
                }
            }
        }
    }
}

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

#main {
  transition: margin-left .5s;
  padding: 16px;
  overflow-x: hidden;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}
